import { UpdateSubscriptionReq } from '@calo/dashboard-types';
import { MacrosService } from '@calo/services';
import { ActivityLevel, Gender, Goal, Kitchen, MacrosPreset, MacrosType } from '@calo/types';
import DateFnsAdapter from '@date-io/date-fns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Divider, InputAdornment, MenuItem, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { getRecord, updateSubscription } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { InputMUI } from 'components';
import { format } from 'date-fns/fp';
import { startCase } from 'lodash-es';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import useUserForm from './useUserForm';
import { DetailedSubscription } from 'lib/interfaces';
import { styles } from './styles';
import { ACTIVITY_LEVELS } from 'lib/constants';

interface UserInformationProps {
  // this is not a proper type. api returns much more but for our use case it fits right here
  selectedCustomer: { value: string } | null;
}

const UserInformation = ({ selectedCustomer }: UserInformationProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { mutateAsync: updateMutation } = useMutation(updateSubscription);

  const { data: subscriptionData, isLoading } = useQuery(['subscriptions', selectedCustomer?.value], getRecord, {
    suspense: false,
    enabled: !!selectedCustomer
  });
  const subscription = subscriptionData as DetailedSubscription;

  const onSubmit = async (data: UpdateSubscriptionReq) => {
    await updateMutation({ id: subscription.id, ...data });
  };

  const { values, setFieldValue } = useUserForm(subscription, onSubmit);

  const handleMacrosPresetChange = (macrosPreset: MacrosPreset, cal: number) => {
    let macrosData = MacrosService.getMacrosBag(
      cal,
      values.dietType!,
      values.macrosData?.weight ?? 0,
      values.macrosData?.activityLevel || ActivityLevel.level1
    );
    if (!(macrosPreset === MacrosPreset.recommended)) {
      macrosData = MacrosService.getCustomMacrosBag(cal, macrosPreset);
      setFieldValue('macrosType', MacrosType.custom);
    }
    setFieldValue('macros.protein.min', macrosData.protein.min);
    setFieldValue('macros.protein.max', macrosData.protein.max);
    setFieldValue('macros.carbs.min', macrosData.carbs.min);
    setFieldValue('macros.carbs.max', macrosData.carbs.max);
    setFieldValue('macros.fat.min', macrosData.fat.min);
    setFieldValue('macros.fat.max', macrosData.fat.max);
    setFieldValue('macrosPreferences.preset', macrosPreset);
  };

  const handleCalChange = (cal: number) => {
    setFieldValue('macros.cal', cal);
    if (values.macrosPreferences?.preset) {
      handleMacrosPresetChange(values.macrosPreferences.preset, cal);
    }
  };

  const handleMacrosType = (macrosType: MacrosType) => {
    if (macrosType === MacrosType.recommended) {
      const calData = MacrosService.getCal(subscription.macrosData);
      const macrosData = MacrosService.getMacrosBag(
        calData,
        values.dietType!,
        values.macrosData?.weight ?? 0,
        values.macrosData?.activityLevel || ActivityLevel.level1
      );
      setFieldValue('macros.cal', calData);
      setFieldValue('macros.protein.min', macrosData.protein.min);
      setFieldValue('macros.protein.max', macrosData.protein.max);
      setFieldValue('macros.carbs.min', macrosData.carbs.min);
      setFieldValue('macros.carbs.max', macrosData.carbs.max);
      setFieldValue('macros.fat.min', macrosData.fat.min);
      setFieldValue('macros.fat.max', macrosData.fat.max);
      setFieldValue('macrosType', MacrosType.recommended);
      setFieldValue('macrosPreferences.preset', undefined);
    } else {
      setFieldValue('macrosType', MacrosType.custom);
    }
  };

  const handleMacrosDataFields = (name: string, macrosType: { min: number; max: number }) => {
    return (
      <Box display={'flex'} flexDirection={'row'} width={'100%'} justifyContent={'space-between'}>
        <Stack display="flex" width="100%" flexDirection={'column'} justifyContent={'space-between'}>
          <Typography
            sx={{
              fontFamily: caloTheme.typography.fontFamily,
              ml: 1,
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '14px'
            }}
            id={`filled-${name}-minimum-helper-text`}
          >
            {name} Minimum
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '19px',
              mt: 1,
              ml: 1
            }}
            id={`filled-macrosType-minimum-helper-text`}
          >
            {macrosType?.min} G
          </Typography>
        </Stack>
        <Stack display="flex" width="100%" flexDirection={'column'} justifyContent={'space-between'}>
          <Typography
            sx={{
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '14px'
            }}
            id={`filled-${name}-maximum-helper-text`}
          >
            {name} Maximum
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '19px',
              mt: 1
            }}
            id={`filled-macrosType-maximum-helper-text`}
          >
            {macrosType?.max} G
          </Typography>
        </Stack>
      </Box>
    );
  };

  if (isLoading) {
    return (
      <Stack spacing={2}>
        <Skeleton variant="rectangular" height={50} sx={{ bgcolor: caloTheme.palette.neutral100 }} />
      </Stack>
    );
  }

  if (subscription) {
    return (
      <>
        <Box width={'100%'} display={'flex'} justifyContent={'space-between'} onClick={() => setIsOpen(!isOpen)}>
          <Typography
            style={{
              fontFamily: 'Roboto',
              fontSize: '19px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '23px',
              letterSpacing: '-0.38px'
            }}
          >
            User Information
          </Typography>
          {isOpen ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
        </Box>
        <Box style={{ marginTop: '4px', display: isOpen ? 'flex' : 'none', flexDirection: 'column' }}>
          <Stack>
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '20px',
                marginBottom: 4,
                marginTop: 4
              }}
              display={isOpen ? 'flex' : 'none'}
            >
              {subscription?.kitchen || Kitchen.BH1}
            </Typography>
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '18px',
                marginBottom: 4,
                marginTop: 4
              }}
              display={isOpen ? 'flex' : 'none'}
            >
              Customer Information
            </Typography>
          </Stack>
          <Stack style={{ marginBottom: 12 }}>
            <TextField
              label="Name"
              placeholder="Enter Name"
              value={subscription?.name}
              name="name"
              disabled={true}
              // onChange={(data) => handleDataChange({ ...subscription, name: data.target.value })}
              id="selected-subscription-name"
              sx={{ marginY: '8px' }}
              inputProps={{
                inputProps: { style: { borderRadius: 16 } },
                style: { borderRadius: 16, height: '12px', marginBottom: 4 }
              }}
            />
            <TextField
              label="Phone Number"
              placeholder="Phone Number"
              value={subscription?.phoneNumber}
              name="phoneNumber"
              disabled={true}
              // onChange={(data) => setFieldValue('name', data.target.value)}
              sx={{ marginY: '8px' }}
              inputProps={{
                inputProps: { style: { borderRadius: 16 } },
                style: { borderRadius: 16, height: '12px', marginBottom: 4 }
              }}
            />
            <TextField
              select
              label="Gender"
              name="gender"
              value={subscription?.macrosData.gender}
              defaultValue={subscription?.macrosData.gender}
              disabled
              sx={{ marginY: '8px' }}
            >
              {Object.values(Gender).map((gender) => (
                <MenuItem key={gender} value={gender}>
                  {startCase(gender)}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Email"
              placeholder="Enter Email"
              value={subscription?.email}
              name="email"
              disabled={true}
              // onChange={(data) => setFieldValue('name', data.target.value)}
              sx={{ marginY: '8px' }}
              inputProps={{
                inputProps: { style: { borderRadius: 16 } },
                style: { borderRadius: 16, height: '12px', marginBottom: 4 }
              }}
            />
            <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ mb: '4px' }}>
              <DesktopDatePicker
                label="Date of Birth"
                disableFuture
                inputFormat="dd/MM/yyyy"
                value={values?.macrosData?.dob}
                maxDate={format('yyyy-MM-dd')(Date.now())}
                renderInput={(params) => <TextField {...params} style={{ marginTop: 8 }} />}
                onChange={() => null}
                disabled
              />
            </LocalizationProvider>
          </Stack>
          <Divider />
          <Stack style={{ marginTop: 8, marginBottom: 12 }}>
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '18px',
                marginBottom: 12,
                marginTop: 4
              }}
            >
              Biometrics
            </Typography>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              style={{ marginTop: 4, width: '100%' }}
            >
              <InputMUI
                debounce
                name="macrosData.height"
                label="Height"
                placeholder="Enter Height"
                id="exact-subscription-height"
                value={values?.macrosData?.height}
                onChange={(data) => {
                  setFieldValue('macrosData.height', data.target.value);
                  onSubmit({
                    ...values,
                    macrosData: {
                      ...values.macrosData,
                      height: +data.target.value
                    }
                  });
                }}
                inputProps={{
                  inputProps: { style: { borderRadius: 8 }, min: 100, max: 300 },
                  style: { borderRadius: 8, width: '70%' },
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>
                }}
              />
              <InputMUI
                debounce
                label="Weight"
                name="macrosData.weight"
                placeholder="Enter Weight"
                id="exact-subscription-weight"
                value={values?.macrosData?.weight}
                onChange={(data) => {
                  setFieldValue('macrosData.weight', data.target.value);
                  onSubmit({
                    ...values,
                    macrosData: {
                      ...values.macrosData,
                      weight: +data.target.value
                    }
                  });
                }}
                inputProps={{
                  inputProps: { style: { borderRadius: 8 }, min: 100, max: 300 },
                  style: { borderRadius: 8, width: '70%' },
                  endAdornment: <InputAdornment position="end">kg</InputAdornment>
                }}
              />
            </Stack>
            <Stack
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              sx={{ width: '100%', marginTop: 2 }}
            >
              <InputMUI
                debounce
                label="Target weight"
                name="values.macrosData.targetWeight"
                placeholder="Enter Target weight"
                value={values?.macrosData?.targetWeight}
                onChange={(data) => {
                  setFieldValue('macrosData.targetWeight', data.target.value);
                  onSubmit({
                    ...values,
                    macrosData: {
                      ...values.macrosData,
                      targetWeight: +data.target.value
                    }
                  });
                }}
              />
              <TextField
                select
                label="Goal"
                name="values.macrosData.goal"
                value={values?.macrosData?.goal}
                style={{ marginTop: 12 }}
                onChange={(data) => {
                  setFieldValue('values.macrosData.goal', data.target.value);
                  onSubmit({
                    ...values,
                    macrosData: {
                      ...values.macrosData,
                      goal: data.target.value as Goal
                    }
                  });
                }}
              >
                {Object.values(Goal).map((goal) => (
                  <MenuItem key={goal} value={goal}>
                    {startCase(goal)}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                type="string"
                name="activityLevel"
                label="Activity level"
                id="exact-subscription-activityLevel"
                style={{ marginTop: 12 }}
                value={values?.macrosData?.activityLevel}
                onChange={(data) => {
                  setFieldValue('macrosData.activityLevel', data.target.value);
                  onSubmit({
                    ...values,
                    macrosData: {
                      ...values.macrosData,
                      activityLevel: data.target.value as ActivityLevel
                    }
                  });
                }}
                InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
              >
                {ACTIVITY_LEVELS.map((activityLevel) => (
                  <MenuItem key={activityLevel.value} value={activityLevel.value}>
                    {activityLevel.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Stack>
          <Divider />
          <Stack style={{ marginTop: 8, marginBottom: 12 }}>
            <Typography style={styles.macrosTitle}>Macros</Typography>
            <TextField
              select
              type="text"
              name="macrosType"
              label="Macros Source"
              value={values?.macrosType}
              placeholder="Select Macros Source"
              style={{ marginTop: 8, marginBottom: 8 }}
              onChange={(data: any) => handleMacrosType(data.target.value)}
              InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            >
              {[
                { value: MacrosType.recommended, label: 'Recommended' },
                { value: MacrosType.custom, label: 'Custom' }
              ].map((macrosType) => (
                <MenuItem key={macrosType.value} value={macrosType.value} onChange={() => onSubmit(values)}>
                  {startCase(macrosType.label)}
                </MenuItem>
              ))}
            </TextField>
            {values.macrosType === MacrosType.custom && (
              <TextField
                select
                type="text"
                name="macrosPreferences.preset"
                label="Macros Preset"
                placeholder="Select Macros Preset"
                style={{ marginTop: 8, marginBottom: 8 }}
                value={values.macrosPreferences?.preset}
                onChange={(data: any) => handleMacrosPresetChange(data.target.value, values.macros!.cal)}
                InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
              >
                {[
                  { value: MacrosPreset.recommended, label: 'Recommended' },
                  { value: MacrosPreset.evenSplit, label: 'Even Split' },
                  { value: MacrosPreset.highCarbs, label: 'High Carbs' },
                  { value: MacrosPreset.highFat, label: 'High Fat' },
                  { value: MacrosPreset.highProtein, label: 'Hight Protein' }
                ].map((macrosPreset) => (
                  <MenuItem key={macrosPreset.value} value={macrosPreset.value} onChange={() => onSubmit(values)}>
                    {startCase(macrosPreset.label)}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <TextField
              label="Calories"
              name="macros.cal"
              value={values.macros?.cal}
              placeholder="Enter Calories"
              style={{ marginTop: 8, marginBottom: 8 }}
              id="exact-subscription-calories"
              onBlur={() => onSubmit(values)}
              onChange={(data) => handleCalChange(+data.target.value)}
              disabled={values.macrosType === MacrosType.recommended}
            />
            <Box
              display={'flex'}
              flexDirection={'row'}
              sx={{ my: 1, width: 'full', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
            >
              {handleMacrosDataFields('Protein', subscription?.macros?.protein)}
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              sx={{ my: 1, width: 'full', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
            >
              {handleMacrosDataFields('Carbs', subscription?.macros?.carbs)}
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              sx={{
                mb: 2,
                mt: 1,
                width: 'full',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row'
              }}
            >
              {handleMacrosDataFields('Fat', subscription?.macros?.fat)}
            </Box>
          </Stack>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box width={'100%'} display={'flex'} justifyContent={'space-between'} onClick={() => setIsOpen(!isOpen)}>
        <Typography style={styles.titleText}>User Information</Typography>
        {isOpen ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
      </Box>
      <Box style={{ marginTop: '4px', display: isOpen ? 'flex' : 'none', flexDirection: 'column' }}>
        <Stack>
          <Typography sx={styles.noSubscriptionText} display={isOpen ? 'flex' : 'none'}>
            No Subscription Found
          </Typography>
        </Stack>
      </Box>
    </>
  );
};
export default UserInformation;
