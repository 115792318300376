import { CreateUserReq, UserGroup } from '@calo/dashboard-types';
import { Country, Kitchen } from '@calo/types';
import { Button, Input, Select } from 'components';
import { useUserKitchens } from 'lib/hooks';
import useUserForm from './useUserForm';

interface UserFormProps {
  onSubmit: (value: Omit<CreateUserReq, 'id'>) => Promise<void>;
  permissionsAndRoles: UserGroup[];
}

const UserForm = ({ onSubmit, permissionsAndRoles }: UserFormProps) => {
  const { handleSubmit, values, handleChange, handleBlur, isSubmitting, isValid, dirty, setFieldValue } = useUserForm(onSubmit);

  const userKitchens: string[] = useUserKitchens();

  return (
    <div className=" p-6 pt-12">
      <form onSubmit={handleSubmit}>
        <Input label="Name" value={values.name} name="name" onChange={handleChange} onBlur={handleBlur} />
        <Input label="Phone number" value={values.phoneNumber} name="phoneNumber" onChange={handleChange} onBlur={handleBlur} />
        <Select
          label="Country"
          value={values.country}
          onChange={(data: any) => setFieldValue('country', data.value)}
          options={Object.values(Country).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select
          label="Kitchen"
          value={values.kitchen}
          onChange={(data: any) =>
            setFieldValue(
              'kitchen',
              data.map((row) => row.value)
            )
          }
          options={userKitchens.map((c) => ({
            value: c,
            label: c
          }))}
          isMulti
          isDisabled={Object.values(Kitchen).filter((r) => r.includes(values.country!)).length === 0}
        />
        <Input label="Email" value={values.email} name="email" onChange={handleChange} onBlur={handleBlur} />
        <Select
          label="Group"
          value={values.group}
          name="group"
          onChange={(data: any) => setFieldValue('group', data.value)}
          options={permissionsAndRoles.map((type) => ({
            value: type.id,
            label: type.groupName
          }))}
          onBlur={handleBlur}
        />
        <Input label="Password" value={values.password} name="password" onChange={handleChange} onBlur={handleBlur} />
        {values.password.length < 8 && (
          <p className="text-red-500 text-xs mb-2 -mt-2">password should be at least 8 characters</p>
        )}
        <Button type="submit" primary loading={isSubmitting} disabled={!dirty || !isValid || isSubmitting} content="Save" />
      </form>
    </div>
  );
};
export default UserForm;
