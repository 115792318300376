import { PermissionService } from '@calo/services';
import { Delivery, FoodActionType, FoodType, Subscription } from '@calo/types';
import { customizeMeal, replaceAddonSubscriptionFood, replaceFood, skipMeal, unSkipMeal } from 'actions';
import { utcToZonedTime } from 'date-fns-tz';
import { FoodWithPosition } from 'lib/interfaces';
import { useMutation } from 'react-query';

interface SwapComponents {
  oldId: string;
  newId: string;
}

export interface FoodActions {
  type: FoodActionType;
  componentId: string[];
  swappedComponents?: SwapComponents[];
}

export const useMealActions = (
  deliveryId: string,
  refetchDelivery: () => void,
  selected: { id: string } | null | undefined,
  subscription: Subscription,
  delivery: Delivery,
  meal: FoodWithPosition,
  mealType: string
) => {
  const { mutateAsync: replaceMutation } = useMutation(replaceFood);
  const { mutateAsync: skipMealMutation } = useMutation(skipMeal);
  const { mutateAsync: unSkipMealMutation } = useMutation(unSkipMeal);
  const { mutateAsync: customizeMealMutation } = useMutation(customizeMeal);
  const { mutateAsync: replaceAddonSubscriptionFoodMutation } = useMutation(replaceAddonSubscriptionFood);

  const isAddonSubscriptionFood = Boolean(meal?.addedAs && meal?.addedAs === 'addonSubscription');
  const isDisabled =
    mealType !== 'meal' ||
    ![FoodType.lunch, FoodType.dinner, FoodType.breakfast, FoodType.caloKids].some((type) => meal.type?.includes(type)) ||
    !PermissionService.deliveryCanBeEdited(delivery);
  const skipIsDisabled =
    isAddonSubscriptionFood ||
    ['meal', 'addon'].includes(meal.addedAs ?? '') ||
    (mealType === 'meal' && !PermissionService.deliveryCanBeEdited(delivery));
  const disabledDeleteMeal = isAddonSubscriptionFood || (mealType === 'meal' && !PermissionService.deliveryCanBeEdited(delivery));

  const disabledCustomizeMeal =
    isAddonSubscriptionFood ||
    (mealType === 'meal' && !PermissionService.deliveryCanBeEdited(delivery, utcToZonedTime(Date.now(), subscription.timezone)));

  const handleReplace = async (sourceId: string, targetId: string, positionIndex: number) => {
    await replaceMutation(
      { id: deliveryId, sourceId, targetId, positionIndex },
      {
        onSuccess: refetchDelivery
      }
    );
  };

  const handleCustomizeMeal = async (
    foodId: string,
    removedIds: string[],
    swappedIds: SwapComponents[],
    applyAlwaysForMeal: boolean,
    foodIndex?: number
  ) => {
    const actions: FoodActions[] = [
      { type: FoodActionType.remove, componentId: removedIds },
      { type: FoodActionType.swap, swappedComponents: swappedIds, componentId: swappedIds.map((swap) => swap.oldId) }
    ].filter((action) => action.componentId.length);

    await customizeMealMutation(
      {
        foodId,
        deliveryId,
        actions,
        applyAlwaysForMeal,
        foodIndex
      },
      {
        onSuccess: refetchDelivery
      }
    );
  };

  const handleSkip = async (isSkipped: boolean, foodId: string) => {
    const mutation = isSkipped ? unSkipMealMutation : skipMealMutation;
    await mutation(
      { deliveryId, foodId },
      {
        onSuccess: refetchDelivery
      }
    );
  };

  const handleReplaceAddonSubscription = async (food: any) => {
    if (selected) {
      await replaceAddonSubscriptionFoodMutation(
        {
          subscriptionId: subscription.id,
          deliveryId: delivery.id,
          sourceId: selected.id,
          targetId: food?.id
        },
        {
          onSuccess: () => {
            refetchDelivery();
          }
        }
      );
    }
  };

  return {
    handleReplace,
    handleCustomizeMeal,
    handleSkip,
    handleReplaceAddonSubscription,
    isDisabled,
    skipIsDisabled,
    disabledDeleteMeal,
    disabledCustomizeMeal
  };
};
