import { CustomMealCategory, DeliveryInstruction, FoodComponentType, WeekDay } from '@calo/types';

export enum Routes {
  root = '/',
  driversMetric = '/drivers-metric',
  driverMetric = '/drivers-metric/:id',
  deliveryPlanner = '/delivery-panner/:kitchen',
  coolerBags = '/cooler-bags',
  user = '/users/:id',
  usersList = '/users',
  newUser = '/user/new',

  newMenu = '/menu/new/:country/:kitchen/:day',
  menuList = '/:brand/:country/:kitchen/menu',
  menu = '/:brand/:kitchen/menu/:id',
  deliveries = '/deliveries',
  delivery = '/deliveries/:id',

  subscriptionUsers = '/subscription-users',

  subscriptions = '/subscriptions',
  subscription = '/subscriptions/:id',
  newSubscription = '/subscriptions/new',

  plansCalculator = '/plans/calculator',

  playgroundFoodList = '/chef-playground/food',
  playgroundFood = '/chef-playground/food/:id',
  playgroundFoodSlug = '/chef-playground/slug/:slug',
  playgroundNewFood = '/chef-playground/food/new',
  playgroundComponentList = '/chef-playground/food-components',
  playgroundComponent = '/chef-playground/food-components/:id',
  playgroundNewComponent = '/chef-playground/food-components/new',
  playgroundIngredientList = '/chef-plaground/ingredients',
  playgroundIngredient = '/chef-playground/ingredients/:id',
  playgroundNewIngredient = '/chef-playground/ingredients/new',

  foodList = '/food',
  food = '/food/:id',
  foodFeedback = '/food/feedback/:slug',
  foodSlug = '/food/slug/:slug',
  newFood = '/food/new/:type',
  foodComponentList = '/food-components',
  foodComponent = '/food-components/:id',
  newFoodComponent = '/food-components/new',
  kitchenFoodStats = '/food-stats',
  kitchenPortionStats = '/portion-stats',
  kitchenIngredientStats = '/ingredient-stats',
  packagingStats = '/packaging-stats',
  kitchenList = '/kitchens',
  kitchen = '/kitchens/:id',
  ingredients = '/ingredients',
  ingredient = '/ingredients/:id',
  newIngredient = '/ingredients/new',
  refunds = '/refunds',
  couponsList = '/coupons',
  coupon = '/coupons/:id',
  newCoupon = '/coupons/new',
  packagesList = '/food-packages',
  package = '/food-packages/:id',
  newPackage = '/food-packages/new',
  packageElementList = '/food-package-element',
  packageElement = '/food-package-element/:id',
  newPackageElement = '/food-package-element/new',
  addonsList = '/:brand/:country/addons-menu',
  addons = '/:country/:kitchen/:brand/addons-menu/:id',
  newAddons = '/addons-menu/new',
  addonsSubscriptionList = '/:brand/:country/addons-subscription-menu',
  addonsSubscription = '/:country/:kitchen/:brand/addons-subscription-menu',
  newAddonsSubscription = '/addons-subscription-menu/new',
  permissions = '/permissions',
  waitingListMap = '/kitchens/:id/waiting-list/:waitingListType/:isAllowed?',
  payment = '/payments',
  invitationStats = '/invitation-stats',
  drivers = '/drivers',
  feedback = '/feedback',
  tickets = '/tickets',
  kdsUser = '/kds-users/:id',
  kdsUsersList = '/kds-users',
  newKdsUser = '/kds-user/new',
  foodLogs = '/food-logs',
  logisticChange = '/delivery-address-change',
  operationsChanges = '/operations-changes',
  gifts = '/gifts',

  supplyChainUsersList = '/supply-chain-users',
  supplyChainUser = '/supply-chain-users/:id',
  newSupplyChainUser = '/supply-chain-user/new',
  accountUsersList = '/account-users',
  accountUser = '/account-users/:id',
  newAccounts = '/accounts/new',
  orders = '/orders',
  supplyChainReportsList = '/supply-chain-reports-list',
  supplyChainMRPReport = '/supply-chain-mrp-report/:kitchen/:day',
  supplyChainMovingCostReport = '/supply-chain-moving-cost-report/:kitchen',
  nutritionists = '/nutritionists',
  complaints = '/complaints',
  specialRequest = '/special-request',
  affiliateProfilesList = '/affiliate-profiles',
  affiliateTransactionsList = '/affiliate-profiles/:id',
  employeePerks = '/employee-perks',
  kdsPermissions = '/kds-permissions',
  retailMenu = '/:brand/:branch/retail-menu',
  retailNewMenu = '/:branch/:brand/retail-menu/new',
  retailOrderList = '/retail/order',
  retailOrderItem = '/retail/order/:id',
  aiDashboardLogistics = '/ai-dashboard/logistics',
  aiDashboardPromptTuning = '/ai-dashboard/prompt-tuning',
  supplyCapZones = '/supply-cap-zones/:kitchen'
}

export enum Action {
  USER_LIST_SET = 'USER_LIST_SET',
  USER_SET = 'USER_SET',

  UI_TOGGLE_VIEW_MODE = 'UI_TOGGLE_VIEW_MODE',
  UI_SET_VIEW_MODE = 'UI_SET_VIEW_MODE',
  UI_TOGGLE_SETTINGS = 'UI_TOGGLE_SETTINGS',
  UI_SET_SETTINGS_VISILE = 'UI_SET_SETTINGS_VISILE',
  UI_TOGGLE_KITCHEN_SETTINGS = 'UI_TOGGLE_KITCHEN_SETTINGS',
  UI_SET_KITCHEN_SETTINGS_VISILE = 'UI_SET_KITCHEN_SETTINGS_VISILE',
  UI_TOGGLE_COUPON_SETTINGS = 'UI_TOGGLE_COUPON_SETTINGS',
  UI_SET_COUPON_SETTINGS_VISILE = 'UI_SET_COUPON_SETTINGS_VISILE'
}

export enum AreaColors {
  COLOR_1 = 'rgba(228, 100, 90, 0.5)',
  COLOR_2 = 'rgba(6, 23, 85, 0.5)',
  COLOR_3 = 'rgba(222, 224, 106, 0.5)',
  COLOR_4 = 'rgba(210, 125, 46, 0.5)',
  COLOR_5 = 'rgba(0, 0, 0, 0.5)',
  COLOR_6 = 'rgba(105, 67, 215, 0.5)'
}

export enum AreaColors2 {
  COLOR_1 = '#89C6A5',
  COLOR_2 = '#4F89A3',
  COLOR_3 = '#7466A2',
  COLOR_4 = '#E4C159',
  COLOR_5 = '#008080',
  COLOR_6 = '#343434',
  COLOR_7 = '#AEAEAE'
}

export enum KitchenLogType {
  food = 'food',
  foodComponent = 'foodComponent',
  ingredient = 'ingredient'
}

export enum MacrosOffset {
  protein = 4,
  carbs = 4,
  fat = 9
}

export enum ViewMode {
  map = 'map',
  list = 'list'
}

export enum AddressViewMode {
  map = 'map',
  form = 'form'
}

export enum PackageType {
  regular = 'regular',
  sauce = 'sauce'
}

export enum SubscriptionPages {
  userInfo = 'User Information',
  deliveries = 'Deliveries',
  plan = 'Plan',
  wallet = 'Wallet',
  transactions = 'Transactions',
  logs = 'Logs',
  feedback = 'Feedback',
  loyalty = 'Loyalty',
  referral = 'Referral'
}

export enum FoodFeedbackPages {
  feedback = 'Feedback',
  complaints = 'Complaints'
}

export enum OperationType {
  mealSwap = 'mealSwap',
  manualDelivery = 'manualDelivery',
  other = 'other'
}

export enum MenuPopulateType {
  breakfast = 'Breakfast',
  lunchAndDinner = 'Lunch & Dinner',
  snacks = 'Snacks',
  caloKids = 'Calo Kids',
  dessert = 'Dessert Boxes',
  salad = 'Salad'
}

export enum AccountPages {
  userInfo = 'User Information',
  deliveries = 'Deliveries'
}

export enum loyaltyTransactionLogStatus {
  added = 'Earned',
  expired = 'Expired',
  added_by_calo = 'Added By Calo',
  redeemed = 'Claimed'
}

export enum ComplaintReason {
  HEALTH_RELATED_CONCERNS_COMPLAINTS = 'Health Related Concerns',
  COLD_SECTION_RELATED_ERRORS = 'Cold Section Related Errors',
  SPILLED_LIQUIDS_OR_FOOD_COMPLAINTS = 'Spilled Liquids or Food',
  DISPATCH_RELATED_ERRORS = 'Dispatch Related Errors',
  QUALITY_RELATED_COMPLAINTS = 'Quality Related Errors',
  FOREIGN_OBJECTS_FOUND = 'Foreign Objects Found',
  FOOD_RELATED_FOREIGN_OBJECTS_FOUND = 'Food Related Foreign Objects',
  NON_FOOD_RELATED_FOREIGN_OBJECTS_FOUND = 'Non-Food Related Foreign Objects'
}

export enum OrderItemType {
  beverage = 'beverage',
  food = 'food',
  merchandise = 'merchandise'
}

export enum SyncAttributesLabels {
  name = 'Arabic Name',
  cookedRawFactor = 'Cooked Raw Factor',
  weight = 'Cooked Weight',
  cups = 'cups',
  cookingStation = 'Stations',
  ingredients = 'Ingredients',
  macros = 'Macros',
  micronutrients = 'Micronutrients',
  measurementUnit = 'Measurement Unit',
  method = 'Method',
  tags = 'Tags',
  image = 'Image',
  standards = 'Standards',
  internalName = 'Internal Name',
  sections = 'Custom Meal Sections'
}

export enum MenuPresentationType {
  daily = 'Daily',
  weekly = 'Weekly'
}

export enum MenuCategory {
  breakfastBalancedFixed = 'Breakfast Balanced Fixed',
  breakfastBalancedRotational = 'Breakfast Balanced Rotational',
  breakfastLowCarbFixed = 'Breakfast Low Carb Fixed',
  breakfastLowCarbRotational = 'Breakfast Low Carb Rotational',
  breakfastHighProtein = 'Breakfast High Protein',
  breakfastVegetarian = 'Breakfast Vegetarian',
  breakfastWeightLoss = 'Breakfast Weight-Loss',
  breakfastGourmet = 'Breakfast Gourmet',
  lunchAndDinnerBalancedFixed = 'Lunch & Dinner Balanced Fixed',
  lunchAndDinnerBalancedRotational = 'Lunch & Dinner Balanced Rotational',
  lunchAndDinnerLowCarbFixed = 'Lunch & Dinner Low Carb Fixed',
  lunchAndDinnerLowCarbRotational = 'Lunch & Dinner Low Carb Rotational',
  lunchAndDinnerHighProtein = 'Lunch & Dinner High Protein',
  lunchAndDinnerVegetarian = 'Lunch & Dinner Vegetarian',
  lunchAndDinnerGourmet = 'Lunch & Dinner Gourmet',
  lunchAndDinnerWeightLoss = 'Lunch & Dinner Weight-Loss',
  snackBalancedFixed = 'Snacks Balanced Fixed',
  snackBalancedRotational = 'Snacks Balanced Rotational',
  snackLowCarbFixed = 'Snacks Low Carb Fixed',
  snackLowCarbRotational = 'Snacks Low Carb Rotational',
  snackHighProtein = 'Snacks High Protein',
  snackGourmet = 'Snacks Gourmet',
  caloKids = 'Calo Kids',
  dessert = 'Dessert Boxes',
  salad = 'Salad'
}

export enum HealthConditions {
  PREGNANCY_BREASTFEEDING = 'PREGNANCY_BREASTFEEDING',
  HYPERTENSION = 'HYPERTENSION',
  HIGH_CHOLESTEROL = 'HIGH_CHOLESTEROL',
  GERD = 'GERD',
  IBS = 'IBS',
  THYROID = 'THYROID',
  HIGH_URIC_ACID = 'HIGH_URIC_ACID',
  ANEMIA = 'ANEMIA',
  G6PD = 'G6PD'
}

export enum MealTabs {
  mealInformation = 'Meal Information',
  components = 'Components',
  nutritionalInformation = 'Nutritional Information',
  ingredients = 'Ingredients',
  packagesInformation = 'Packages Information',
  notes = 'Notes'
}

export const DeliveryInstructionsLabels: {
  [key in DeliveryInstruction]: string;
} = {
  [DeliveryInstruction.CALL_ME_WHEN_YOU_REACH]: 'Call me when you reach',
  [DeliveryInstruction.LEAVE_AT_RECEPTION]: 'Leave at reception',
  [DeliveryInstruction.LEAVE_AT_THE_DOOR]: 'Leave at the door',
  [DeliveryInstruction.RING_MY_DOORBELL]: 'Ring my doorbell'
};

export const dayMappings = {
  [WeekDay.SUNDAY]: 'Sun',
  [WeekDay.MONDAY]: 'Mon',
  [WeekDay.TUESDAY]: 'Tue',
  [WeekDay.WEDNESDAY]: 'Wed',
  [WeekDay.THURSDAY]: 'Thu',
  [WeekDay.FRIDAY]: 'Fri',
  [WeekDay.SATURDAY]: 'Sat'
};

export enum WeekDayIndex {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export enum ImageType {
  FOOD = 'food',
  COMPONENT = 'food-component',
  PACKAGE = 'food-package',
  INGREDIENT = 'ingredients'
}

export enum InputValueRestrictionType {
  macros = 'macros',
  quantity = 'quantity'
}

export const InputValueRestrictions = {
  [InputValueRestrictionType.macros]: {
    before: 4,
    after: 6
  },
  [InputValueRestrictionType.quantity]: {
    before: 5,
    after: 5
  }
};

export enum PerksStatus {
  pending = 'pending',
  done = 'done'
}

export enum EmployeePerksStatus {
  success = 'success',
  pending = 'pending',
  failed = 'failed'
}

export const ComponentCountByCustomMealType = {
  [CustomMealCategory.meal]: {
    [FoodComponentType.base]: 1,
    [FoodComponentType.protein]: 1,
    [FoodComponentType.topping]: 3,
    [FoodComponentType.side]: 1,
    [FoodComponentType.sauce]: 1
  },
  [CustomMealCategory.salad]: {
    [FoodComponentType.base]: 2,
    [FoodComponentType.protein]: 1,
    [FoodComponentType.topping]: 1,
    [FoodComponentType.side]: 2,
    [FoodComponentType.sauce]: 1
  },
  [CustomMealCategory.rice]: {
    [FoodComponentType.base]: 1,
    [FoodComponentType.protein]: 1,
    [FoodComponentType.topping]: 1,
    [FoodComponentType.side]: 3,
    [FoodComponentType.sauce]: 1
  },
  [CustomMealCategory.sandwich]: {
    [FoodComponentType.base]: 1,
    [FoodComponentType.protein]: 1,
    [FoodComponentType.topping]: 3,
    [FoodComponentType.side]: 1,
    [FoodComponentType.sauce]: 1
  }
};

export enum FormOperation {
  create = 'CREATE',
  update = 'UPDATE'
}

export enum FoodTagKeys {
  new = 'new',
  glutenFree = 'glutenFree',
  dairyFree = 'dairyFree'
}

export enum DocsType {
  invoice = 'invoice',
  frontLabel = 'frontLabel',
  backLabel = 'backLabel',
  portioningGuidelines = 'portioningGuidelines',
  portioningGuidelinesStats = 'portioningGuidelinesStats'
}

export enum FoodStatsType {
  subscriptions = 'subscriptions',
  businesses = 'businesses',
  charity = 'charity'
}

export enum Province {
  WP = 'WP',
  EP = 'EP',
  RH = 'RH',
  QM = 'QM',
  BH = 'BH',
  QA = 'QA',
  AE = 'AE',
  KW = 'KW'
}

export enum RetailMenuItemTag {
  FROYO = 'FROYO',
  HOT_DRINK = 'HOT_DRINK',
  MATCHA = 'MATCHA',
  SMOOTHY = 'SMOOTHY',
  SALAD = 'SALAD',
  COLD_DRINK = 'COLD_DRINK',
  RICE_MEAL = 'RICE_MEAL',
  SANDWICH = 'SANDWICH',
  CALO_MEAL = 'CALO_MEAL',
  SWEET = 'SWEET',
  WRAP = 'WRAP',
  SNACK = 'SNACK',
  BONE_BROTH = 'BONE_BROTH'
}
