import { DeliveryTime, Kitchen } from '@calo/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { format, setDay } from 'date-fns/fp';
import { startCase } from 'lodash-es';
import { useState } from 'react';
import styles from './styles';
import { useQuery } from 'react-query';
import { getRecord } from 'actions/index';
import { DetailedSubscription } from 'lib/interfaces';

interface UserPlanDetailsProps {
  // this is not a proper type. api returns much more but for our use case it fits right here
  selectedCustomer: { value: string } | null;
}

const UserPlanDetails = ({ selectedCustomer }: UserPlanDetailsProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: subscriptionData, isLoading } = useQuery(['subscriptions', selectedCustomer?.value], getRecord, {
    suspense: false,
    enabled: !!selectedCustomer
  });
  const subscription = subscriptionData as DetailedSubscription;

  const handleRenderText = (type: string, text: string) => {
    return (
      <Stack sx={{ my: '8px', ml: '1px' }}>
        <Typography variant="caption" sx={{ ...styles.typeText }} display={isOpen ? 'flex' : 'none'}>
          {type}
        </Typography>
        <Typography variant="caption" sx={{ ...styles.valueText }} display={isOpen ? 'flex' : 'none'}>
          {text}
        </Typography>
      </Stack>
    );
  };

  if (isLoading) {
    return (
      <Stack spacing={2}>
        <Skeleton variant="rectangular" height={50} sx={{ bgcolor: caloTheme.palette.neutral100 }} />
      </Stack>
    );
  }

  if (subscription) {
    return (
      <>
        <Box width={'100%'} display={'flex'} justifyContent={'space-between'} onClick={() => setIsOpen(!isOpen)}>
          <Typography style={{ ...styles.titleText }}>User Plan</Typography>
          {isOpen ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
        </Box>
        <Box style={{ marginTop: '4px', display: isOpen ? 'flex' : 'none', flexDirection: 'column' }}>
          <Stack>
            <Typography sx={{ ...styles.subInfoText }} display={isOpen ? 'flex' : 'none'}>
              Subscription Details
            </Typography>
          </Stack>
          {handleRenderText('Package', subscription?.plan.id || '')}
          {handleRenderText('Diet', subscription?.plan?.dietType || '')}
          {handleRenderText('Frequency', subscription?.plan.frequency || '----')}
          {handleRenderText(
            'Delivery Time',
            subscription?.deliveryTime === DeliveryTime.evening
              ? `${startCase(subscription?.deliveryTime)} (6-10 PM) `
              : subscription?.kitchen === Kitchen.GB1
                ? `${startCase(subscription?.deliveryTime)} (2-6 AM)`
                : `${startCase(subscription?.deliveryTime)} (7-11 AM)` || '---'
          )}
          {handleRenderText('Cycle Starting on', format('dd/MM/yyyy')(new Date(subscription?.cycleStartedAt)) || '----')}
          {handleRenderText('First Day', format('dd/MM/yyyy')(new Date(subscription?.startedAt)) || '----')}
          {handleRenderText(
            'Last Delivery Date',
            format('dd/MM/yyyy')(new Date(subscription?.expectedLastDeliveryDay)) || '----'
          )}
          {handleRenderText(
            'Expected Last Delivery Date',
            format('dd/MM/yyyy')(new Date(subscription?.expectedLastDeliveryDay)) || ''
          )}
          <Divider
            sx={{
              strokeWidth: '1px',
              color: caloTheme.palette.neutral100,
              height: '2px',
              borderWidth: 0,
              alignSelf: 'stretch'
            }}
          />
          <Stack>
            <Typography sx={{ ...styles.subInfoText }} display={isOpen ? 'flex' : 'none'}>
              Delivery Days
            </Typography>
            <Stack sx={{ ...styles.deliveryDaysStack }}>
              {subscription.deliveryDays.map((day: number, index) => (
                <Box key={index} sx={{ ...styles.deliveryDaysBox }}>
                  <Grid sx={{ ...styles.grid }}>
                    <Typography sx={{ ...styles.gridText }}>
                      <p className="mx-2">{format('EEE')(setDay(day)(Date.now()))}</p>
                    </Typography>
                  </Grid>
                </Box>
              ))}
            </Stack>
          </Stack>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box width={'100%'} display={'flex'} justifyContent={'space-between'} onClick={() => setIsOpen(!isOpen)}>
        <Typography style={styles.titleText}>User Plan</Typography>
        {isOpen ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
      </Box>
      <Box style={{ marginTop: '4px', display: isOpen ? 'flex' : 'none', flexDirection: 'column' }}>
        <Stack>
          <Typography sx={styles.noSubscriptionText} display={isOpen ? 'flex' : 'none'}>
            No Subscription Found
          </Typography>
        </Stack>
      </Box>
    </>
  );
};
export default UserPlanDetails;
